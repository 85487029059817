//./plugins/posthog.js
import posthog from "posthog-js";
import { POSTHOG_API_KEY } from "@/config";

export default {
  install(app) {
    const posthogInstance = posthog.init(
      POSTHOG_API_KEY,
      {
        api_host: 'https://us.i.posthog.com',
      }
    );

    // Wait for feature flags to load
    posthog.onFeatureFlags(() => {
      console.log('PostHog feature flags loaded');
    });

    app.config.globalProperties.$posthog = posthogInstance;
  },
};
