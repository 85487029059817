import { createPinia, defineStore } from 'pinia'
const pinia = createPinia()
import { version } from "@/config/index"
import service from '@/servers/fetch'
import { toFixed } from "@/utils/common";
import bus from 'vue3-eventbus'
// import { TradeStore } from "@/stores/trade";
export const HomeStore = defineStore('Home', {
    state: () => {
        return {
            pairList: [],
            currentSymbol: "",
            botList: [],
            exhibitBot: {},
            botSnapshotData: {},
            userBalances: [],
            exchangeList: [],
            exchangeLoading: true,
            currentBotOrderHistory: [],
            currentAccount: "",
            botListCardHeight: 436,
            tmpSymbols:["BTCUSDT","ETHUSDT","BNBUSDT","RNDRUSDT","FETUSDT","ARUSDT","RLCUSDT","CRVUSDT","ENSUSDT","LTCUSDT","ADAUSDT","XRPUSDT","TRXUSDT","ETCUSDT","LINKUSDT","MATICUSDT"],
            tmpPairs:["BTC/USDT","ETH/USDT","BNB/USDT","RND/RUSDT","FET/USDT","AR/USDT","RLC/USDT","CRV/USDT","ENS/USDT","LTC/USDT","ADA/USDT","XRP/USDT","TRX/USDT","ETC/USDT","LINK/USDT","MATIC/USDT"],
            symbolInfos:{},
            // quoteDatas:{}
            totalProfit:{
                realisedProfit:0,
                realisedProfitPct:0,
                totalProfit:0,
                totalProfitPct:0,
                transactionFee:0,
                unrealisedProfit:0,
                unrealisedProfitPct:0,
            },
            botOverallDetail:[],
            botPerformanceSnapshot:[],
            allPairInfo:{},
            showPerformanceData:{
                botSnapshotData:{},
                botPerformance:{},
                botData:{},
                showDetails:false,
            },
            allBotPerformance:{},
            botListSelectAccount:'All',
            profitModeData:{
                id:'',
                mode:'usd'
            }
        }
    },
    getters: {
        formatSymbols() {
            return Object.values(this.symbolInfos)
        },
        formatBotList() {
            let data = this.botList;
            if(data.length == 0) {
                return []
            }
            data = data.filter((v) => {
                let ex = this.formatExchangeList.filter(item => {
                    return item.id == v.exchangeAccount
                })
                return ex && ex.length > 0
            });
            return data
        },
        formatExchangeList() {
            if (this.exchangeList.length == 0) {
                return []
            }
            return this.exchangeList.map(item => {
                return {
                    ...item,
                    demo:0,
                    exchange_name:item.accountName,
                    exchange_type:item.accountType,
                    sandbox:0,
                    status:0
                }
            })
        },
        rankBotList() {
            if(this.formatBotList.length == 0) {
                return []
            }
            let data = JSON.parse(JSON.stringify(this.formatBotList))
            data = data.sort((a, b) => {
                return b.profitPct - a.profitPct
            })
            return data
        },
        formatUserBalance() {
            if (this.formatExchangeList.length == 0 || this.userBalances.length == 0) {
                return []
            }
            const ids = this.formatExchangeList.map(item => {
                return item.id
            })
            let arr = this.userBalances.map(item => {
                let frees = item.free
                let totals = item.total
                let useds = item.used
                let balances = []
                for(const key in totals) {
                    balances.push({
                        asset:key,
                        free:frees[key],
                        total:totals[key],
                        used:useds[key]
                    })
                }
                return {
                    exchangeAccountId:item.exchangeAccountId,
                    balances
                }
            })
            let result = arr.filter(item => {
                return ids.indexOf(item.exchangeAccountId) > -1
            })
            return result
        }
    },
    actions: {
        resetData() {
            this.showPerformanceData = {
                botSnapshotData:{},
                botPerformance:{},
                botData:{},
                showDetails:false,
            }
            this.exchangeList = []
            this.exchangeLoading = true
            this.botOverallDetail = {}
            this.currentBotOrderHistory = []
            this.currentAccount = ''
            this.botList = []
            this.exhibitBot = {}
            this.botSnapshotData = {}
            this.userBalances = []
            this.botPerformanceSnapshot = []
        },
        setProfitModeData(v) {
            this.profitModeData = v
        },
        setBotListSelectAccount(v) {
            this.botListSelectAccount = v
        },
        setAllBotPerformance(v) {
            this.allBotPerformance = v
        },
        setPerformanceData(v) {
            this.showPerformanceData = v
        },
        setAllPairInfo(v) {
            this.allPairInfo = v
        },
        setExchangeList(v) {
            this.exchangeList = v
        },
        setBotOverallDetail(v) {
            this.botOverallDetail = v
        },
        setCurrentBotOrderHistory(v) {
            this.currentBotOrderHistory = v
        },
        setBotListHeight(v) {
            this.botListCardHeight = v
        },
        setCurrentAccount(v) {
            this.currentAccount = v
        },
        setPairList(v) {
            this.pairList = v
        },
        setCurrentSymbol(v) {
            this.currentSymbol = v
        },
        setBotList(v) {
            this.botList = v
        },
        setExhibitBot(v) {
            this.exhibitBot = v
        },
        setBotSnapshotData(v) {
            this.botSnapshotData = v
        },
        setBalances(v) {
            this.userBalances = v
        },
        setBotPerformanceSnapshot(v) {
            this.botPerformanceSnapshot = v
        },
        getPriceByCoin(currency) {
            if(currency == 'USDT' || currency == 'USDC') {
                return 1;
            }
            let data = this.pairList;
        
            if (data.length == 0) {
                return 0;
            }
            let tmp = data.filter((item) => {
                return item.symbol == `${currency}/USDT`
            });
            if (tmp.length == 0) {
                return 0;
            }
            return tmp[0].close;
        },
        //unrealised profit
        getUnrealisedProfitByPerformances(data) {
            if(this.allBotPerformance.length == 0) return 0;
            let current = this.allBotPerformance[data.id]
            if(!current){
              return '--'
            }
            let baseCoin = data.symbol.split('/')[0]
            const baseCurrentPrice = this.getPriceByCoin(baseCoin)
            let profit;
            if(current.botType == 'LONG_GRID') {
              profit = (Number(baseCurrentPrice) - current.initBasePrice) * current.baseAmount
            }else {
              if(Number(baseCurrentPrice) == 0 || current.initBasePrice == 0) {
                profit = 0
              } else {
                profit = current.quoteAmount * (1 / Number(baseCurrentPrice) - 1 / current.initBasePrice) * Number(baseCurrentPrice)
              }
            }
            return toFixed(profit);
        },
        //realised profit
        formatRealisedProfitByPerformances(data) {
            const totalProfit = this.formatProfitByPerformances(data);
            const unrealisedProfit = this.getUnrealisedProfitByPerformances(data)
            return toFixed(Number(totalProfit) - Number(unrealisedProfit));
        },
        //realised profit APY
        formatRealisedProfitPctByPerformances(data) {
            let runHours = (new Date() - new Date(data.createdAt)) / 1000 / 60 / 60;
            let apy = 0;
            let realisedProfit = this.formatRealisedProfitByPerformances(data)
            if(data.botType == "LONG_GRID") {
                apy = Number(realisedProfit) / runHours * (24 * 365) / Number(data.stake)
            }else {
                apy = Number(realisedProfit) / runHours * (24 * 365) / (Number(data.stake) * Number(data.initPrice))
            }
            if(apy <= -1) {
                return -100
            }
            return toFixed(apy * 100,2);
        },
        // PNL pct
        formatProfitPctByPerformances(data) {
            const totalProfit = this.formatProfitByPerformances(data);
            let apy = 0;
            if(data.botType == 'LONG_GRID') {
                apy = Number(totalProfit) / Number(data.stake) * 100
            }else {
                apy = Number(totalProfit) / (Number(data.stake) * Number(data.initPrice)) * 100
            }
            return toFixed(apy,2)
        },
        //total profit
        formatProfitByPerformances(data) {
            if(Object.keys(this.allBotPerformance).length == 0){
                return data.profit ? toFixed(data.profit) : '--';
            }
            let current = this.allBotPerformance[data.id]
            if(!current) {
                return data.profit ? toFixed(data.profit) : '--';
            }
            let baseCoin = data.symbol.split('/')[0]
            const baseCurrentPrice = this.getPriceByCoin(baseCoin)
            const quoteAmount = current.quoteAmount
            const baseAmount = current.baseAmount
            const stake = current.stake;
            let total_profit = 0;
            if(current.botType == 'LONG_GRID') {
                total_profit = quoteAmount + baseAmount * baseCurrentPrice
            }else {
                total_profit = quoteAmount + (baseAmount - stake) * baseCurrentPrice
            }
            return toFixed(total_profit)
        },
        async getSymbolInfo(data) {
            let response = await service({
                url: `${version}/getSymbolInfo`,
                method: 'get',
                params: data
            })
            this.symbolInfos = response.data
            let symbols = Object.keys(response.data).map(item => {
                return item.replace('/','')
            })
            this.tmpSymbols = symbols.slice(0,200);
            this.tmpPairs = Object.keys(response.data).slice(0,200);
            return response
        },
        async getPriceTicker(data) {
            let response = await service({
                url: `${version}/getPriceTicker`,
                method: 'get',
                params: data
            })
            return response
        },
        async getMarketPair(data, single = false) {
            const sortList = ['LTC/USDT', 'LINK/USDT', 'DOT/USDT', 'BCH/USDT', 'AVAX/USDT', 'ADA/USDT', 'TRX/USDT', 'DOGE/USDT', 'XRP/USDT', 'SOL/USDT', 'BNB/USDT', 'ETH/USDT', 'BTC/USDT']
            let response = await service({
                url: `${version}/getPriceTicker`,
                method: 'get',
                params: data
            })
            if (!single) {
                this.pairList = Object.values(response.data)
                this.pairList = this.pairList.map(item => {
                    let currentData = this.formatSymbols.filter(item2 => {
                        return item.symbol == item2.symbol
                    })
                    return {
                        ...item,
                        infos:{
                            ...currentData[0]
                        }
                    }
                }).sort((a,b) => {
                    return sortList.indexOf(b.symbol) - sortList.indexOf(a.symbol)
                })
                this.setCurrentSymbol('BTC/USDT')
            }
            bus.emit('quote-init')
            return response
        },
        async getBotList(data) {
            let response = await service({
                url: `${version}/getBot`,
                method: 'get',
                params: data,
            })
            this.botList = response.data;
            return response
        },
        async getBotById(data) {
            let response = await service({
                url: `${version}/getBot`,
                method: 'get',
                params: data,
            })
            return response
        },
        //获取机器人绩效
        async getPortfolioSnapshotBot(data) {
            let response = await service({
                url: `${version}/portfolio/snapshot/bot`,
                method: 'get',
                params: data
            })
            this.botSnapshotData = response.data
            return response
        },
        async getPortfolioBalances(data) {
            let response = await service({
                url: `${version}/getBalance`,
                method: 'get',
                params: {
                    hideZeroPosition:true,
                    ...data
                },
            })
            this.userBalances = response.data
            return response
        },
        async getBalanceById(data) {
            let response = await service({
                url: `${version}/getBalance`,
                method: 'get',
                params: data,
            })
            return response
        },
        async getExchangeCheckList(data) {
            this.exchangeLoading = true;
            let response = await service({
                url: `${version}/getExchangeAccount`,
                method: 'get',
                params: data
            })
            this.setExchangeList(response.data)
            this.exchangeLoading = false;
            return response;
        },
        async createBot(data) {
            let response = await service({
                url: `${version}/createBot`,
                method: 'post',
                data
            })
            return response;

        },
        async getOrderListBotOrder(data) {
            let response = await service({
                url: `${version}/order/listBotOrder`,
                method: 'get',
                params: data,
            })
            return response;
        },
        async modifyBot(data) {
            let response = await service({
                url: `${version}/updateBot`,
                method: 'post',
                data,
            })
            return response;
        },
        async pauseBot(data) {
            let response = await service({
                url: `${version}/bot/pauseBot`,
                method: 'post',
                data
            })
            return response
        },
        async resumeBot(data) {
            let response = await service({
                url: `${version}/bot/resumeBot`,
                method: 'post',
                data
            })
            return response
        },
        async stopBotFetch(data) {
            let response = await service({
                url: `${version}/updateBot`,
                method: 'post',
                data
            })
            return response
        },
        async getBotPerformance(data) {
            let response = await service({
                url: `${version}/getBotPerformance`,
                method: 'get',
                params:data
            })
            return response 
        },
        async getBotPerformanceSnapshot(data) {
            let response = await service({
                url: `${version}/getBotPerformanceSnapshot`,
                method: 'get',
                params:data
            })
            return response
        },
        async getBotOverallDetail(data) {
            let response = await service({
                url: `${version}/getBotOverallDetail`,
                method: 'get',
                params:data
            })
            return response
        },
        // uncomment when flg-105 is ready
        // async getFeatureTokenCost() {
        //     let response = await service({
        //       url:`${version}/getFeatureTokenCost`,
        //       method: 'get'
        //     })
        //     return response
        // },
        // async getCreditBalance(data) {
        //     let response = await service({
        //       url:`${version}/getCreditBalance`,
        //       method: 'get',
        //       params: data
        //     })
        //     return response
        // },
    }
})

export default pinia;