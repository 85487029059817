let config = {
    BASEURL:"https://api.algo724.com",
    WS_URL:"wss://api-wss.algo724.com/ws?token=17672b37-19b4-40a5-80fb-cdbf2cc4c369",
    HAWKEYE_WS_URL:'http://localhost:3000'
}
if (process.env.NODE_ENV == 'development') {
    config = {
        BASEURL:"https://api-uat.algo724.com",
        WS_URL:"wss://api-wss.algo724.com/ws?token=17672b37-19b4-40a5-80fb-cdbf2cc4c369",
        HAWKEYE_WS_URL:'http://localhost:3000'
    }
}
if(location.origin.indexOf('uat') > -1) {
    config = {
        BASEURL:"https://api-uat.algo724.com",
        WS_URL:"wss://api-wss.algo724.com/ws?token=17672b37-19b4-40a5-80fb-cdbf2cc4c369",
        HAWKEYE_WS_URL:'http://localhost:3000'
    }
}

const version = "/api/v1"
const NETWORK_INFURA_API_KEY = "03f196e11a744e288d4ccca6e22006f3"
const ALCHEMY_API_KEY = 'ivWm3G-Hxe7LYXixLvQZ2jWwkEM754Wb'
const GOOGLE_CLIENT_ID = '496870199022-gqlonrdrm2kalscrf4j7hvtd8n5q0521.apps.googleusercontent.com'
const WALLET_CONNECT_ID = "6ebc8da6df970367a7d1e8426a3cedf4";
const POSTHOG_API_KEY = 'phc_677tyPAwc90Or6KopxP5zNwmWjd8Ja20OMI8rLJMve';

export default config;

export { 
    version, 
    NETWORK_INFURA_API_KEY, 
    ALCHEMY_API_KEY, 
    GOOGLE_CLIENT_ID, 
    WALLET_CONNECT_ID,
    POSTHOG_API_KEY 
}
