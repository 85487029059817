import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

import 'vuetify/styles'
import vuetify from "./plugins/vuetify"
import i18n from './plugins/i18n'
import Message from "./plugins/toast/index.js";
import echarts from './plugins/echarts'
import * as commonFunctions from "@/utils/common"
import './registerServiceWorker'
import * as Sentry from "@sentry/vue";
import posthogPlugin from './plugins/posthog'
 

export const app = createApp(App)

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: "https://5e2c92a9948f307d4b6e12872012ef05@o1209894.ingest.us.sentry.io/4507842242150400",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    enableDeduplication: true,
    debug: true,
    enablePrintBreadcrumbs: true,
    enableMemoryPressureBreadcrumbs: false,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.algo724\.com\/api/, /^https:\/\/api-uat\.algo724\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router).use(vuetify).use(createPinia()).use(i18n).use(Message).use(posthogPlugin);
app.provide('i18n', i18n); // 提供 i18n 变量
app.mixin({ methods: { ...commonFunctions } })
app.config.globalProperties.echarts = echarts;
app.mount('#app')